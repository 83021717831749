import React from "react";
import { useLocationProvider } from "../context/LocationProvider";
import Icon from "../assets/Icon.png";
import "./Header.css";

export default function Header() {
  const { location } = useLocationProvider();
  return (
    <>
      <div className="content w-[85%] lg:w-[40rem]">
        <div className="mr-10">
          <img className="icon-image" src={Icon} alt="IconImage" />
        </div>
        <div>
          {location.region && (
            <>
              <span className="color-white font-25">
                {location?.city}, {location?.region}
              </span>
              <br />
            </>
          )}

          <span className="color-white">Weather Updates</span>
        </div>
      </div>
    </>
  );
}
