import React from "react";
import "./BoardMainCard.css";
import BoardCard from "./BoardCard";
import Image1 from "../assets/Layer-7.webp";
import Image2 from "../assets/Layer-6.webp";
import Image3 from "../assets/Layer-8.webp";

const BoardMainCard = () => {
  return (
    <div className="mb-8 w-[85%] lg:w-[40rem] mx-auto flex bg-white items-center justify-evenly rounded-b-md p-5 lg:flex-nowrap flex-wrap ">
      <BoardCard title="Hourly Weather Updates" image={Image1} />
      <BoardCard title="Live Weather Radar" image={Image2} />
      <BoardCard title="10 Day Forecast" image={Image3} />
    </div>
  );
};

export default BoardMainCard;
