import React from "react";
import "./Card.css";
import Forecast from "./ForeCast"; // import your other component here
import { WeatherProvider } from "./../context/WeatherProvider";

const Card = () => {
  return (
    <div className="my-component  py-5 sm:py-9 px-3 sm:px-9 w-[85%] lg:w-[40rem] ">
      <h2 className="text-[#008cd5] mb-3 font-bold text-2xl  sm:text-3xl">
        Get Your Local Forecast
      </h2>
      <WeatherProvider>
        <Forecast />
      </WeatherProvider>
    </div>
  );
};

export default Card;
