import React from "react";
import ForecastCard from "./ForeCastCard";
import Button from "./Button";
import "./ModalCard.css";
import { useLocationProvider } from "../context/LocationProvider";
import { useSearchParams } from "react-router-dom";

export default function ModalCard({ weatherData, location }) {
  const [isShowData, setIsShowData] = React.useState(false);
  const { setLocation } = useLocationProvider();

  const [queryParameters] = useSearchParams();

  const sub1 = queryParameters.get("sub1");
  const sub2 = queryParameters.get("sub2");
  const sub3 = queryParameters.get("sub3");
  const Sub3 = queryParameters.get("Sub3");

  React.useEffect(() => {
    setTimeout(() => {
      setIsShowData(true);
    }, 10000);
  }, []);

  React.useEffect(() => {
    console.log(weatherData);
    const { city_name, region } = weatherData?.[0] || {};
    if (city_name && region) setLocation({ city: city_name, region });
  }, [weatherData]);

  return (
    <ForecastCard className="w-[17rem] sm:w-[30rem] md:w-[50rem] lg:w-full bg-lightgrey min-h-[calc(100vh-8rem)] flex flex-col justify-between">
      <div>
        <div className="titleStyle">
          <b>Get The Latest Local Forecast for {location}</b>
        </div>

        <div className="flex justify-center">
          <Button
            onClick={() => {
              window.open(
                `http://track.hudsonlabsclick.info/37cd56ae-a503-4bab-a31d-fa44826c55c6?Sub1=${sub1}&Sub2=${sub2}&Sub3=${
                  Sub3 || sub3
                }&HudsonLP=LP1`
              );
            }}
          />
        </div>

        <p className="p-info">
          Get your local weather forecast with WeatherInfo. Receive WeatherInfo
          by clicking the Continue button
        </p>
      </div>

      <div>
        <p className="p-data">
          {!isShowData || !weatherData
            ? "Your Weather Will Load Shortly."
            : `${weatherData?.[0]?.city_name} - Currently ${
                (weatherData?.[0]?.temp * 9) / 5 + 32
              } °F`}
        </p>

        <p
          className="p-link"
          onClick={() => {
            window.open(
              "http://track.hudsonlabsclick.info/37cd56ae-a503-4bab-a31d-fa44826c55c6?var5=lp1"
            );
          }}
        >
          If you don't want to wait , click here to get WeatherInfo
        </p>
      </div>
    </ForecastCard>
  );
}
