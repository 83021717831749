import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";

// Create a context
export const LocationContext = createContext();

// Create a provider for the context
export const LocationProvider = ({ children }) => {
  const [location, setLocation] = useState({
    city: "",
    region: "",
  });

  const fetchData = async () => {
    try {
      // Get the current city and region based on the IP address of the system
      const { data } = await axios.get(
        `https://ipinfo.io/json?token=${process.env.REACT_APP_IP_INFO_TOKEN}`
      );
      setLocation({ city: data.city, region: data.region });
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <LocationContext.Provider value={{ location, setLocation }}>
      {children}
    </LocationContext.Provider>
  );
};

export const useLocationProvider = () => useContext(LocationContext);
