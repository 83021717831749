import ImgReview1 from "../assets/reviews/1.jpeg";
import ImgReview2 from "../assets/reviews/2.jpeg";
import ImgReview3 from "../assets/reviews/3.jpeg";
import ImgReview4 from "../assets/reviews/4.jpeg";
import ImgReview5 from "../assets/reviews/5.jpeg";

export const logo = require("./../assets/WeatherViewLogo.png");

export const REVIEW_SLUGS = {
  weatherChannelApp: "weather-channel-app",
  accuWeatherApp: "accu-weather-app",
  yahooWeatherApp: "yahoo-weather-app",
  weatherUndergroundApp: "weather-underground-app",
  weather: "weather-app",
};

export const REVIEWS = [
  {
    name: "The Weather Channel App",
    image: ImgReview2,
    slug: REVIEW_SLUGS.weatherChannelApp,
  },
  {
    name: "AccuWeather App",
    image: ImgReview3,
    slug: REVIEW_SLUGS.accuWeatherApp,
  },
  {
    name: "The Yahoo Weather app",
    image: ImgReview4,
    slug: REVIEW_SLUGS.yahooWeatherApp,
  },
  {
    name: "Weather Underground App",
    image: ImgReview5,
    slug: REVIEW_SLUGS.weatherUndergroundApp,
  },
  {
    name: "Weather.com",
    image: ImgReview1,
    slug: REVIEW_SLUGS.weather,
  },
];

export const pageContent = [
  {
    slugs: REVIEW_SLUGS.weatherChannelApp,
    name: "The Weather Channel App",
    link: "https://play.google.com/store/apps/details?id=com.weather.forecast.weatherchannel&hl=en_US&gl=US",
    content1: `The Weather Channel app is a comprehensive weather forecasting tool available for download on the Google Play Store.`,
    content2: `First and foremost, the app provides users with accurate and up-to-date weather forecasts for their current location, as well as any other location they may be interested in. This feature alone is incredibly helpful for planning daily activities and staying informed about potential weather hazards. 
    <br/>
    <br/>
    The app also offers a wide range of additional features, including hourly and 10-day forecasts, severe weather alerts, radar maps, and videos. The user interface is intuitive and easy to navigate, with detailed weather information presented in a clear and concise manner.`,
    content3: `One standout feature of The Weather Channel app is its personalized weather alerts, which can be customized to notify users of specific weather conditions such as thunderstorms, tornadoes, and hurricanes. This is especially useful for those who live in areas prone to severe weather events.
    <br/>
    <br/> 
    Additionally, the app provides useful information about air quality, pollen levels, and UV indexes, which can be incredibly beneficial for individuals with allergies or other health concerns.
    `,
    img: ImgReview2,
  },
  {
    slugs: REVIEW_SLUGS.accuWeatherApp,
    name: "AccuWeather App",
    link: "https://play.google.com/store/apps/details?id=com.accuweather.android",
    content1: `AccuWeather is a popular weather forecasting app available for download on the Google Play Store.`,
    content2: `The app provides users with accurate and up-to-date weather information for their current location, as well as any other location they may be interested in. It offers hourly and daily weather forecasts, as well as extended forecasts up to 15 days in advance. The user interface is intuitive and easy to navigate, with detailed weather information presented in a clear and concise manner.
    <br/>
    <br/>
    One standout feature of AccuWeather is its MinuteCast feature, which provides minute-by-minute precipitation forecasts for the next two hours. This is incredibly useful for those who want to know exactly when it will start or stop raining, and can help with planning outdoor activities.
    <br/>
    <br/>
    Another useful feature of the app is its severe weather alerts, which notify users of potential weather hazards such as thunderstorms, tornadoes, and hurricanes. The app also offers real-time weather radar maps and satellite imagery, providing users with a visual representation of current weather conditions.`,
    content3: `AccuWeather also provides information about air quality, pollen levels, and UV indexes, which can be helpful for individuals with allergies or other health concerns.
    <br/>
    <br/>
    Overall, AccuWeather is a highly recommended app for anyone who wants to stay informed about weather conditions in their area. With its accurate forecasts, comprehensive features, and user-friendly interface, it's a reliable tool for planning daily activities and staying safe during severe weather events.`,
    img: ImgReview3,
  },

  {
    slugs: REVIEW_SLUGS.yahooWeatherApp,
    name: "The Yahoo Weather App",
    link: "https://play.google.com/store/apps/details?id=com.yahoo.mobile.client.android.weather",
    content1: `The Yahoo Weather app is a popular weather forecasting tool available for download on the Google Play Store.`,
    content2: `The app provides users with accurate and up-to-date weather information for their current location, as well as any other location they may be interested in. It offers hourly and daily weather forecasts, as well as extended forecasts up to 10 days in advance. The user interface is sleek and modern, with detailed weather information presented in an easy-to-read format.
    <br/>
    <br/>
    One standout feature of the Yahoo Weather app is its dynamic home screen, which displays a beautiful photo of the user's current location along with current weather conditions. The app also offers real-time weather radar maps and satellite imagery, providing users with a visual representation of current weather conditions.`,
    content3: ` Another useful feature of the app is its severe weather alerts, which notify users of potential weather hazards such as thunderstorms, tornadoes, and hurricanes. The app also provides information about air quality, pollen levels, and UV indexes, which can be helpful for individuals with allergies or other health concerns.
     <br/>
     <br/>
     One unique aspect of the Yahoo Weather app is its crow sourcing feature, which allows users to submit photos of weather conditions in their area. These photos are then displayed in the app, providing a unique and personalized perspective on weather conditions around the world.`,
    img: ImgReview4,
  },

  {
    slugs: REVIEW_SLUGS.weatherUndergroundApp,
    name: "Weather Underground App",
    link: "https://play.google.com/store/apps/details?id=com.yahoo.mobile.client.android.weather",
    content1: `The Underground Weather App is a comprehensive weather forecasting tool available for download on the Google Play Store.`,
    content2: `The app provides users with accurate and up-to-date weather information for their current location, as well as any other location they may be interested in. It offers hourly and daily weather forecasts, as well as extended forecasts up to 10 days in advance. The user interface is clean and modern, with detailed weather information presented in an easy-to-read format.
     <br/>
     <br/>
     One standout feature of the Underground Weather App is its hyper local forecasting, which provides weather information specific to the user's exact location. This is incredibly useful for those who want to know exactly what the weather will be like in their immediate vicinity.`,
    content3: `The app also offers real-time weather radar maps and satellite imagery, providing users with a visual representation of current weather conditions. Additionally, the app provides information about air quality, pollen levels, and UV indexes, which can be helpful for individuals with allergies or other health concerns.
   <br/>
   <br/>
   Another unique aspect of the Underground Weather App is its user-generated content, which includes photos, weather observations, and comments from other users in the area. This can provide a unique and personalized perspective on weather conditions in the user's area.
  `,
    img: ImgReview5,
  },

  {
    slugs: REVIEW_SLUGS.weather,
    name: "Weather.com",
    link: "https://weather.com/en-PK/weather/today/l/PKXX0006:1:PK?Goto=Redirected",
    content1: `The Weather Channel website, weather.com, is a highly popular and comprehensive weather forecasting tool available for free on the internet. Features and functionality as described on the website.`,

    content2: `The website provides users with accurate and up-to-date weather information for their current location, as well as any other location they may be interested in. It offers hourly and daily weather forecasts, as well as extended forecasts up to 15 days in advance. The user interface is modern and easy to navigate, with detailed weather information presented in an organized and clear manner.
   <br/>
   <br/>
   One standout feature of the Weather Channel website is its radar maps, which provide real-time weather data for locations around the world. These maps allow users to track storms, monitor precipitation, and view temperature trends over time.
   <br/>
   <br/>
   The website also offers severe weather alerts, which notify users of potential weather hazards such as thunderstorms, tornadoes, and hurricanes. The alerts can be customized to provide notifications for specific locations and types of weather events.`,

    content3: `Another useful feature of the website is its health and wellness section, which provides information about air quality, pollen levels, and UV indexes. This can be helpful for individuals with allergies or other health concerns.
       <br/>
       <br/>
       The website also offers news and information about weather-related events and phenomena, as well as educational resources for users interested in learning more about weather and climate.`,
    img: ImgReview1,
  },
];
