import React from "react";
import "./BoardCard.css";

const BoardCard = ({ title, image }) => {
  return (
    <>
      <div className="contentCard">
        <div className="mb-8 board-card-title font-semibold">{title}</div>

        <div className=" w-54   md:w-44 lg:h-28">
          <img className="image" src={image} alt={title} />
        </div>
      </div>
    </>
  );
};

export default BoardCard;
