/* eslint-disable no-restricted-globals */
import React, { createContext, useContext, useState } from "react";

const WeatherContext = createContext();

export const WeatherProvider = ({ children }) => {
  const [weatherData, setWeatherData] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchWeatherData = async (location) => {
    setLoading(true);

    try {
      const apiKey = process.env.REACT_APP_WEATHER_API_TOKEN;
      const apiUrl = `https://api.weatherbit.io/v2.0/current?${
        isNaN(location) ? "city" : "postal_code"
      }=${location}&key=${apiKey}`;

      const response = await fetch(apiUrl);
      const { data } = await response.json();
      setWeatherData(data);
    } catch (error) {
      console.error(error);
      setWeatherData(null);
    }
    setLoading(false);
  };

  const openUrl = (location) => {
    const apiKey = process.env.REACT_APP_WEATHER_API_TOKEN;
    const apiUrl = `https://api.weatherbit.io/v2.0/current?${
      isNaN(location) ? "city" : "postal_code"
    }=${location}&key=${apiKey}`;
    window.open(apiUrl, "_blank");
  };

  return (
    <WeatherContext.Provider
      value={{ weatherData, loading, openUrl, fetchWeatherData }}
    >
      {children}
    </WeatherContext.Provider>
  );
};

export const useWeatherProvider = () => useContext(WeatherContext);
