import React from "react";
import "./TopBar.css";
import { useLocationProvider } from "../context/LocationProvider";

const TopBar = () => {
  const { location } = useLocationProvider();
  return (
    <>
      <header className="header">
        <div className="banner">
          <span className="color-heading">Weather Update! </span>
          <span className="color-white">
            Get the local updates for {location?.city}
          </span>
        </div>
      </header>
    </>
  );
};

export default TopBar;
