import { logo } from "../../utils/constant";
import { Link, useLocation } from "react-router-dom";
import { pageContent } from "../../utils/constant";

const Review = () => {
  const { pathname } = useLocation();

  const content = pageContent.find(
    (content) => content.slugs === pathname.split("/")[2]
  );

  return (
    <div>
      <div className="bg-[#b1d1e6] p-2">
        <div className="container mx-auto">
          <Link to="/">
            <img
              width={260}
              style={{ display: "block" }}
              src={logo}
              alt="logo"
            />
          </Link>
        </div>
      </div>

      <div className="container mx-auto mt-16 mb-20 p-10">
        <h2 className="font-bold text-5xl text-[#0389d3] ">{content.name}</h2>
        <div className="w-5/6 mt-3">
          <p dangerouslySetInnerHTML={{ __html: content.content1 }}></p>
        </div>

        <div className="w-5/6 mt-10">
          <p dangerouslySetInnerHTML={{ __html: content.content2 }}></p>
        </div>

        <div className="mt-5 md:h-[25rem] w-[80%] ">
          <img
            src={content.img}
            className="w-full max-h-full max-w-full"
            alt={content.name}
          />
        </div>

        <div className="w-5/6 mt-10">
          <p dangerouslySetInnerHTML={{ __html: content.content3 }}></p>
        </div>

        <div className="mt-5">
          <strong>Link:</strong>{" "}
          <a className="text-sm text-blue-500 italic" href={content.link}>
            {content.link}{" "}
          </a>
        </div>
      </div>
    </div>
  );
};

export default Review;
