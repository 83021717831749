import "./App.css";
import { LocationProvider } from "./context/LocationProvider";
import Home from "../src/screens/Home";
import Review from "../src/screens/Review";
import Reviews from "./screens/Reviews";

import { Route, Routes } from "react-router-dom";

function App() {
  return (
    <LocationProvider>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/lp1" element={<Home />} />
        <Route path="/reviews" element={<Reviews />} />
        <Route path="/reviews/:reviewSlug" element={<Review />} />
      </Routes>
    </LocationProvider>
  );
}

export default App;
