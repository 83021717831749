import React from "react";
import "./ForeCastCard.css";

const ForecastCard = ({ children, className = "" }) => (
  <div
    className={`bg-white rounded-xl shadow-xl p-4 md:p-4 lg:p-5 mx-auto max-w-xl ${className}`}
  >
    {children}
  </div>
);

export default ForecastCard;
