
import ReviewCard from "../../components/ReviewCard/index";
import { Link } from "react-router-dom";
import { logo } from "../../utils/constant";

import { REVIEWS } from "../../utils/constant";
const Reviews = () => {
  return (
    <>
    <div className="bg-[#b1d1e6] p-2">
    <div className="container mx-auto">
      <Link to="/">
        <img
          width={260}
          style={{ display: "block" }}
          src={logo}
          alt="logo"
        />
      </Link>
    </div>
  </div>
    <div className="container mx-auto mb-14 ">
    <h1 className="mt-16 text-center font-semibold text-4xl">Reviews</h1>

    <div className="flex gap-7 justify-center flex-wrap xl:flex-nowrap sm:p-0 p-5">
      {REVIEWS.map(({ image, name, slug }) => (
        <ReviewCard {...{ image, name, slug }} />
      ))}
    </div>
  </div>
    </>
      
  );
};

export default Reviews;
