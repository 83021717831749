import React from "react";
import Cloud from "../assets/cloud.png";
import "./Button.css";
const Button = (props) => {
  return (
    <button className="button_style" {...props}>
      <img src={Cloud} alt="Cloud" className="icon" />
      <h3 className="h1">Continue</h3>
    </button>
  );
};

export default Button;
