import Card from "../../components/Card";
import BoardMainCard from "../../components/BoardMainCard";
import TopBar from "../../components/TopBar";
import Header from "../../components/Header";

const Home = () => (
  <div>
    <div className="main">
      <TopBar />
      <div className="container mx-auto">
        <Header />
        <Card />
        <BoardMainCard />
      </div>
    </div>
  </div>
);

export default Home;
