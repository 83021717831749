import React, { useEffect } from "react";
import Button from "./Button";
import ForecastCard from "./ForeCastCard";
import Pin from "../assets/pin.png";
import "./ForeCast.css";
import { useWeatherProvider } from "../context/WeatherProvider";
import { useLocationProvider } from "../context/LocationProvider";
import ModalCard from "./ModalCard";
import Modal from "react-modal";

const Forecast = () => {
  const { loading, openUrl, weatherData, fetchWeatherData } =
    useWeatherProvider();
  const ipLocation = useLocationProvider();
  const [location, setLocation] = React.useState("");
  const [modalIsOpen, setIsOpen] = React.useState(false);

  useEffect(() => {
    setLocation(ipLocation?.location?.city);
  }, [ipLocation]);

  function closeModal() {
    setIsOpen(false);
  }

  const onClick = () => {
    fetchWeatherData(location);
    setIsOpen(true);
  };

  return (
    <ForecastCard>
      <>
        <div className="container flex gap-2 mx-auto flex-wrap sm:flex-nowrap justify-center sm:ml-12 sm:mb-2  sm:mt-2 lg:ml-10 lg:mb-3">
          <div>
            <img src={Pin} alt="Pin" className="forecast-icon" />
          </div>
          <div className="title-and-input">
            <div className="forecast-title text-center sm:text-left">
              Enter Zip or City
            </div>
         
          </div>
        </div>
        <input
        type="text"
        placeholder="Zip code or city"
        className="forecast-input"
        onChange={(e) => setLocation(e.target.value)}
        value={location || ""}
        />
        <div className="forecast-container button-flex-container">
          <Button onClick={onClick} disabled={!location || loading} />
        </div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Example Modal"
          className="modal-content"
        >
          <ModalCard
            location={location}
            openUrl={openUrl}
            weatherData={weatherData}
          />
        </Modal>
      </>
    </ForecastCard>
  );
};

export default Forecast;
